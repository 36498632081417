.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 0px;
}

p {
    margin: 0px;
}