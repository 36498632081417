.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding-bottom: 40px;
}

.divider {
    height: 2px;
    width: 72%;
    background-color: gray;
}

ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0px;
}

li {
    text-decoration: none;
    list-style: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5em;
    font-weight: 300;
    
}

a {
    text-decoration: none;
    color: black;
}