#contactContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

form {
    width: 500px;
    display: flex;
    flex-direction: column;
}

input {
    text-decoration: none;
    border: none;
    border-bottom: 1px solid gray;
    height: 30px;
    margin:10px;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 2em;
}

input[type="submit"] {
    text-decoration: none;
    border: none;
    border-bottom: 1px solid gray;
    height: 60px;
    margin:10px;
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 2em;
}

textarea {
        text-decoration: none;
        border: none;
        border-bottom: 1px solid gray;
        height: 100px;
        margin:10px;
        font-family: 'M PLUS Rounded 1c', sans-serif;
        font-size: 2em;
}

input:focus, textarea:focus {
    outline: none;
}