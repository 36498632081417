.landing {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    padding-bottom: 30px;
}

.landingLeft > h1, h3 {
    text-align: left;
    margin: 0px 10px;
}

.backgroundImage {
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100%;
}

.dividerWindow {
    height: 400px;
    width: 100%;
    background-color: transparent;
}

.landing2 {
    height: 700px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: white;
}

